.icon-border {
  width: rem(20);
  height: rem(20);
}

.btn-animation-close {
  top: rem(-8);
  right: 0;
}

.sub-text span {
  font-family: $primary-bold-font;
  color: $secondary;
  font-size: rem(26);
}

.custom-right-block-review {
  .custom-dropdown-item {
    font-size: rem(12);
    background: $bg-list;
  }
}
// Review listing page
.reviews-list {
  list-style: none;
}

.page-reviews-list {
  .max-height-300 {
    max-height: rem(300);
  }

  .custom-right-block-review {
    .advance-search-button {
      position: relative;
      font-family: inherit;
      font-size: rem(12);
    }

    .dropdown {
      width: rem(200);
    }

    .advanced-dropdown {
      overflow: visible;
      min-width: rem(150);
      background-color: white;
      position: absolute;
      font-size: rem(12);
      top: 100%;
      z-index: 99;
      box-shadow: 0 rem(6) rem(10) rgba(0, 64, 88, 0.15);
      right: rem(0);
      width: 100%;
    }

    .filter-list {
      height: rem(44);
      padding-top: rem(4);
    }

    .icon-sync-updated {
      svg {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        @include transition(all 0.2s linear);

        path {
          stroke: rgba($secondary, 0.3);
        }
      }

      &:hover svg {
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
      }
    }
  }

  .google-tooltip {
    width: rem(250);
    right: rem(-60);
    left: inherit;
    transform: translateX(0);
  }

  .highlight-link {
    cursor: pointer;
    color: $primary;
    font-size: rem(14);
    @include transition(all 0.3s linear);

    &:hover {
      color: white;
    }
  }
}

.b-list {
  margin: 0x;
  padding: 0;

  .reply-btn {
    height: rem(28);
    border: solid rem(1) $dark-gray;

    &:hover {
      border: solid rem(1) $primary;
      opacity: 1;
    }
  }

  .list-item-google {
    background: white;
  }

  .list-item {
    background: $review-list-background;
  }

  .list-item-google,
  .list-item {
    list-style: none;
    cursor: pointer;
    min-height: rem(75);
    border: rem(1) solid white;

    &.default-cursor {
      cursor: default;
    }
    .btn-share {
      @include opacity(0.5);
    }

    .user-details {
      border-radius: 50%;
      height: rem(48);
      width: rem(48);
      min-width: rem(48);
      vertical-align: top;
      border: rem(1) solid $tertiary;

      .tick-mark {
        width: rem(22);
        height: rem(22);
        border-radius: 50%;
        position: absolute;
        right: rem(-7);
        bottom: 0;
        line-height: rem(12);
        text-align: center;
      }
    }

    .reviews-list-top {
      .star-empty svg {
        path {
          fill: $dark-gray;
        }
      }
    }

    .reviewer-title {
      color: $secondary;
      font-size: rem(14);
    }

    .right-content {
      min-width: rem(160);
      text-align: right;

      .delete-button {
        border: solid rem(1) $danger;
        height: rem(28);

        i svg {
          path:nth-of-type(1) {
            stroke: $danger;
          }

          path:nth-of-type(2) {
            stroke: $danger;
          }

          path:nth-of-type(3) {
            stroke: $danger;
          }

          path:nth-of-type(4) {
            stroke: $danger;
          }
        }

        &:hover {
          background-color: $danger;

          i svg {
            path:nth-of-type(1) {
              stroke: white;
            }

            path:nth-of-type(2) {
              stroke: white;
            }

            path:nth-of-type(3) {
              stroke: white;
            }

            path:nth-of-type(4) {
              stroke: white;
            }
          }
        }
      }

      &:hover .btn-share {
        @include opacity(1);
      }
    }
  }

  .alert-info {
    padding-left: rem(20);
    position: relative;
    margin-bottom: rem(12);
  }

  .alert-icon {
    top: rem(-2);
    left: 0;
  }

  .review-contents {
    margin-left: rem(68);

    .reply-form {
      padding: 0;
      margin-top: rem(20);

      .reply-message,
      .reply-message-google {
        position: relative;
        font-size: rem(14);
        line-height: rem(19);
        color: $light-text-color;
        margin: rem(10) 0 0;
        padding: rem(14) rem(80) rem(14) rem(15);
        border-radius: rem(4);
      }

      .reply-message {
        background-color: $light-gray;
      }

      .reply-message-google {
        background-color: white;

        &:hover {
          .reply-right-content {
            opacity: 1;
            visibility: visible;
          }
        }

        .reply-right-content {
          opacity: 0;
          visibility: hidden;
          right: rem(10);
          top: 50%;
          transform: translateY(-50%);
        }

        .icon-trash {
          width: rem(13);

          svg {
            path {
              stroke: $danger;
            }
          }
        }

        .icon-edit {
          width: rem(16);

          svg {
            path {
              fill: $icon-gray;
            }
          }

          &:hover {
            svg {
              path {
                fill: $primary;
              }
            }
          }
        }

        .icon-notifi {
          width: rem(16);

          svg {
            path {
              fill: $icon-gray;
            }
          }
        }
      }
    }
  }
}

.readmore-btn {
  color: $primary;
  font-weight: normal;
  cursor: pointer;
  text-decoration: none;
  font-family: $secondary-bold-font;
  font-size: rem(14);
}

.details-modal {
  .b-list .review-contents .reply-form {
    .reply-message-google {
      background-color: $light-gray;
    }

    .details-modal-textarea {
      min-height: rem(90);
    }

    .modal-btn-reply svg path {
      fill: white;
      stroke: white;
    }

    .modal-btn-reply,
    .modal-btn-cancel {
      font-size: rem(12);
    }
  }
}

// review feedback page
.page-reviews-feedback-list {
  .max-height-300 {
    max-height: rem(300);
  }

  .custom-right-block-review {
    .advance-search-button {
      position: relative;
      font-family: inherit;
      font-size: rem(12);
    }

    .dropdown {
      width: rem(200);
    }

    .filter-list {
      height: rem(44);
      padding-top: rem(4);
    }
  }

  .user-details {
    border-radius: 50%;
    height: rem(48);
    width: rem(48);
  }

  .review-source {
    width: rem(30);
    height: rem(30);
    margin-right: rem(10);
    vertical-align: middle;
    border: rem(1) solid $dark-gray;
    background-color: white;
    border-radius: 50%;
  }

  .button-padding-right {
    border-radius: rem(4);
    border: rem(1) solid $dark-gray;
    padding: rem(6);
    cursor: pointer;
    float: right;
    height: rem(30);

    .reply-btn-img {
      margin-top: rem(-23);
    }
  }

  .feedback-popup {
    margin: 0;
    padding: 0;

    .message {
      font-size: rem(14);
      line-height: rem(19);
      color: $light-text-color;

      .reply-form {
        .reply-form-feedback {
          height: auto;

          .textarea-height {
            height: 8rem;
          }
        }

        .reply-message {
          position: relative;
          background-color: $light-gray;
          font-size: rem(14);
          line-height: rem(20);
          color: $light-text-color;
          padding: rem(5) rem(10);
          border-radius: rem(4);

          &:hover {
            .reply-right-content {
              opacity: 1;
              visibility: visible;
            }
          }

          .reply-right-content {
            opacity: 0;
            visibility: hidden;
            right: rem(10);
            top: 50%;
            transform: translateY(-50%);
          }

          .icon-trash {
            width: rem(13);

            svg {
              path {
                stroke: $danger;
              }
            }
          }

          .icon-edit {
            width: rem(16);

            svg {
              path {
                fill: $icon-gray;
              }
            }

            &:hover {
              svg {
                path {
                  fill: $primary;
                }
              }
            }
          }
        }
      }
    }

    .sub-title {
      color: $light-text-color;
      font-size: rem(12);
      font-family: $primary-font;
    }

    .footer-icon-replay {
      svg path {
        stroke: white;
      }
    }

    .custom-palceholder {
      width: rem(171);
      height: rem(51);
    }

    .readmore-btn {
      color: $primary;
      cursor: pointer;
      text-decoration: none;
      font-family: $primary-bold-font;
      font-size: rem(14);
    }

    .button-padding-right {
      margin-right: rem(50);
    }
  }

  .span-bar {
    color: $dark-gray;
  }
}

//----------------------------------------  new styles from here      ------------------------

.outline-icon {
  width: rem(30);
  height: rem(30);
  display: inline-block;
  vertical-align: middle;
  margin-left: rem(5);
  cursor: pointer;
  position: relative;
  border: rem(1) solid $border-color;
  @include border-radius(rem(4));

  .code-icon {
    display: inline-block;
    max-height: rem(20);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include opacity(0.7);
  }
}

.embedded-code-popup {
  .embedded-code {
    word-wrap: break-word;
    font-family: $primary-font;
  }

  .b-embedded-code {
    background: $background-code;
  }

  .replicte-icon {
    svg {
      width: rem(15);

      path {
        fill: $light-text-color;
        @include opacity(0.5);
      }
    }

    svg:hover {
      path {
        fill: $primary;
      }
    }
  }

  .replicte-icon-position {
    top: 0;
    right: 0;
  }

  .url-replicte-icon-position {
    top: rem(4);
    right: rem(-10);
  }
}

.character-limit {
  height: rem(6);
  background: $bg-progressbar;
  @include border-radius(rem(10));
  overflow: hidden;
  span {
    height: rem(6);
    background: $primary;
    @include border-radius(rem(10));
  }
}

.character-left {
  text-align: left;
  font-size: rem(12);
  line-height: rem(22);
}

.b-share-popup {
  .message-box {
    height: rem(100);
  }
}

.icon-block {
  width: rem(30);
  height: rem(20);

  svg {
    width: rem(20);
    height: rem(20);
  }
}

.remove-btn {
  background-color: $danger;
  border: solid rem(1) $danger;
  border-radius: rem(4);
  color: $body-bg;

  &:hover {
    color: $body-bg;
  }
}

.page-reviewRequest-list {
  .delete-btn {
    font-family: $secondary-bold-font;
    &:hover {
      color: $danger;
      border: solid rem(1) $danger;
    }
  }

  .delete-btn-icon svg path {
    fill: $danger;
  }

  .custom-tab-content .custom-modal-close {
    top: rem(-70);
  }
}

//Review Widget
.create-review-widget {
  .b-scroll-box {
    padding: rem(20);
    max-height: calc(100vh - 210px);
    overflow-y: auto;
    overflow-x: hidden;
    @include scrollbar;
  }

  .widget-color-settings {
    width: auto;
    border-radius: rem(100);
    margin: rem(0) rem(20) rem(20) rem(0);
    display: inline-flex;
  }

  .color-picker-popover-cover {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .widget-color-picker {
    width: rem(25);
    height: rem(25);
    border-radius: 50%;
    cursor: pointer;
    border: rem(1) solid $border-color;
    @include transition(all 0.2s linear);

    &:hover {
      transform: scale(1.2);
    }
  }

  .color-picker-label {
    display: inline-block;
    margin-bottom: rem(0);
  }

  .color-picker-popover {
    position: absolute;
    z-index: 2;
    top: rem(30);
  }

  .color-picker-cover {
    top: rem(0);
    right: rem(0);
    bottom: rem(0);
    left: rem(0);
  }
}
.b-widget-header {
  box-shadow: 0 rem(6) rem(18) rgba(0, 0, 0, 0.07);
}
.btn-widget-feeback {
  @include border-radius(rem(32));
}

.remove-dot {
  list-style-type: none;
  width: 100%;
  header {
    border-bottom: rem(1) solid white;
  }
}

.widget-header {
  font-size: rem(20);
  font-family: $primary-bold-font;
  font-weight: 700;
}

.rotate_icon,
.rotate_icon-down {
  width: rem(30);
  cursor: pointer;
  background: white;
  @include transition(all 0.3s linear);

  svg {
    @include opacity(0.5);
    path {
      fill: $secondary;
    }
  }
  &:hover {
    background: $dark-gray;
  }
}

.rotate_icon {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  border-radius: 0 0 rem(4) rem(4);
}

.rotate_icon-down {
  border-radius: 0 0 rem(4) rem(4);
}

.rating-outer {
  display: inline-block;
  position: relative;
  width: rem(104);
  top: rem(-15);
  height: rem(14);

  .star-rating {
    height: rem(15);
    width: rem(104);
    background: url('https://dufzo4epsnvlh.cloudfront.net/image/rating-star.png') no-repeat left top;
  }

  .rating-point {
    position: absolute;
    left: 0;
    top: 0;
    width: rem(104);
    height: rem(15);
    background: url('https://dufzo4epsnvlh.cloudfront.net/image/rating-star-active.png') no-repeat left top;
  }
}

.time-ago {
  margin-left: rem(10);
  color: $darker-gray;
  font-weight: normal;
}

.preview-box-shadow {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);

  .widget-branding {
    color: white;
    font-family: $primary-font;
    font-size: rem(12);
  }
}

.header-loader {
  width: rem(190);
  height: rem(30);
}

.fav-icons {
  width: rem(14);
  height: rem(14);
}

.icon-heart-line:hover svg path {
  fill: $folder-active-color;
}

.icon-heart-fill svg path {
  fill: $folder-active-color;
}

.icon-share {
  svg path {
    fill: $icon-dark;
  }

  &:hover svg path {
    fill: $primary;
  }
}

.b-icon-shareable {
  width: rem(22);
}

.icon-close-fill {
  svg {
    path {
      fill: red;
      stroke: white;
    }
  }
}

.icon-tick-round {
  svg {
    path {
      fill: green;
      stroke: white;
    }
  }
}

.icon-tick-round,
.icon-close-fill {
  svg {
    width: rem(20);
    height: rem(20);
  }
}

.header-loader {
  width: rem(190);
  height: rem(30);
}

.review-preview-msg {
  .review-preview-btn {
    font-size: rem(10);
    height: rem(26);
    padding: rem(3) rem(10);
    vertical-align: top;
    background: $dark-gray;
    color: $primary;
    line-height: rem(20);
    margin-top: rem(20);
  }

  .review-msg-block {
    border-radius: rem(4);
    margin-bottom: rem(10);
    margin-top: rem(20);
    padding: rem(8) rem(10);
    border: solid rem(1) $dark-gray;
    word-wrap: break-word;
    min-height: rem(48);
  }
}

.review-msg-link {
  border: rem(1) solid $dark-gray;
  display: inline-block;
  border-radius: rem(4);
  margin-top: rem(20);
}

.icon-page-back {
  width: rem(12);
  height: rem(12);
}

.icon-edit-reset {
  width: rem(14);

  svg path {
    fill: $primary;
  }
}

.review-date {
  color: $dark-gray;
}

.btn-reply-outline {
  font-size: rem(12);
  color: $secondary;
  border: rem(1) solid $border-color;

  svg path {
    stroke: $primary;
  }

  &:hover {
    border-color: $primary;
    color: $primary;
  }
}

.icon-widget-update {
  width: rem(22);
  @include opacity(0.4);
  @include transition(all 0.5s linear);
}

.edit-widget-custom-tooltip {
  cursor: pointer;

  &:hover .icon-widget-update {
    @include opacity(1);
  }
}

.link-text {
  color: $primary;
  cursor: pointer;
}

.filter-active-icon {
  width: rem(10);
  left: 0;
  top: 50%;
  @include transform(translateY(-50%));
  svg {
    width: 100%;
  }
}

// ////////////// trial preview page
.page-review-trial-preview {
  .b-header {
    svg {
      width: rem(24);

      path {
        stroke: $primary;

        &:nth-last-of-type(1) {
          stroke: inherit;
        }

        &:nth-last-of-type(3) {
          fill: $primary;
          stroke: inherit;
        }

        &:nth-last-of-type(4) {
          stroke: inherit;
        }
      }
    }

    .title-text {
      font-size: rem(18);
    }

    .symbol-registered {
      font-size: rem(21);
      font-family: $secondary-light-font;
    }

    .sub-title-text {
      font-size: rem(20);
    }
  }

  .section-trial-list {
    max-width: rem(810);
    margin-bottom: rem(60);

    .title-text {
      font-size: rem(20);
    }

    .b-trial-list {
      padding-left: rem(50);
    }

    .icon-badge {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .trial-list-item {
      font-size: rem(16);
    }
  }

  .section-trial-testimonial {
    max-width: rem(850);
    padding: rem(20);
    margin-bottom: rem(40);
    background: $grey-background;

    .testimonial {
      font-size: rem(16);
    }

    .author-name {
      font-size: rem(14);
    }
  }

  .section-trial-connect,
  .section-trial-footer {
    max-width: rem(740);
    margin-bottom: rem(40);

    .title-text {
      font-size: rem(20);
    }

    .sub-title {
      font-size: rem(16);
    }

    .box-title-text,
    .content-para {
      font-size: rem(16);
    }
  }
}

.alert-info {
  background: $alert-info;
}
.hours-glass {
  width: rem(15);
  path {
    fill: $black;
  }
}

// ////////////// end trial preview page
